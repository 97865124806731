// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpointxs": "0",
	"breakpointsm": "640",
	"breakpointmd": "992",
	"breakpointlg": "1200",
	"breakpointxl": "1440",
	"breakpoint2xl": "1920",
	"breakpoint3xl": "2400",
	"color-black": "#000",
	"color-white": "#fff",
	"color-gray": "#25252b",
	"color-primary": "#ff382e",
	"color-primary_lighten": "#c7c7c7",
	"color-accent": "#dff197",
	"color-dark": "#25252b",
	"color-light": "#e8e9ea",
	"color-yellow": "#f0aa00",
	"color-red": "#e73938",
	"color-green": "#90c25e",
	"color-orange": "#f18619"
};
module.exports = ___CSS_LOADER_EXPORT___;
